import { useThrottle } from '@/hooks/useThrottle';
import { useEffect } from 'react';

export function useThrottledOnScroll(callback: () => void, delay: number, element: HTMLElement | Window = window, enable = true) {
  const throttledCallback = useThrottle(callback, delay);

  useEffect(() => {
    if (enable) {
      element.addEventListener('scroll', throttledCallback);
      return () => {
        element.removeEventListener('scroll', throttledCallback);
      };
    }
  }, [throttledCallback, enable]);
}
