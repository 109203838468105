import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import Link, { LinkProps } from '@/components/Link';
import { useTranslation } from 'next-i18next';
import GiqBayanatLogo from '@/assets/svg/giq_bayanat.svg';
import AidBayanatLogo from '@/assets/svg/aid_bayanat.svg';
import UaeSALogo from '@/assets/svg/uaesa_logo.svg';
import { getConfig } from '@/config';
import { useSession } from 'next-auth/react';
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useThrottledOnScroll } from '@/hooks/useThrottledOnScroll';
import useIsMobile from '@/hooks/useIsMobile';
import { useRouter } from 'next/router';

const Layout = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(({ selected, ...props }: LinkProps & { selected?: boolean }) => {
  return <Link {...props} scroll={false} variant="h7" color={selected ? 'secondary' : 'text.primary'} />;
})`
  text-decoration: none;
  text-transform: uppercase;
  transition: ${({ theme }) =>
    theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.emphasized,
      duration: theme.transitions.duration.short,
    })};
  &:hover {
    transform: scale(1.1);
  }
`;

export const HEADER_HEIGHT = 84;

const { showUaesa, defaultProduct } = getConfig();

const Navigation = [
  {
    href: '/#home',
    label: 'home:home',
  },
  {
    href: '/#about',
    label: 'home:about',
  },
  {
    href: '/#features',
    label: 'home:features',
  },
  {
    href: '/#how',
    label: 'home:how_works',
  },
  {
    href: '/#contact',
    label: 'home:contact',
  },
  {
    href: '/help',
    label: 'home:help',
  },
  {
    href: '/marketplace',
    label: 'home:marketplace',
  },
];

interface Props {
  scrollableContainerRef?: RefObject<HTMLElement>;
}

interface MobileMenuProps {
  navigation: {
    href: string;
    label: string;
  }[];
}
const MobileMenu: FC = () => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        ref={anchorRef}
        aria-haspopup="true"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Icon>menu</Icon>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" disablePortal>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <Paper>
            <MenuList>
              {Navigation.map((nav) => (
                <MenuItem key={nav.label}>
                  <StyledLink key={nav.label} href={nav.href}>
                    {t(nav.label)}
                  </StyledLink>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

interface MobileNotice {
  open: boolean;
}

const MobileNotice: FC<MobileNotice> = ({ open }) => {
  const { t } = useTranslation(['common']);
  const [dialogOpen, setDialogOpen] = useState(open);
  return (
    <Dialog open={dialogOpen}>
      <DialogContent>
        <DialogContentText>{t('mobileNotice')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>close</Button>
      </DialogActions>
    </Dialog>
  );
};

const Header: FC<Props> = ({ scrollableContainerRef }) => {
  const { t } = useTranslation(['common', 'home']);
  const { status } = useSession();
  const [active, setActive] = useState<string>('');
  const isMobile = useIsMobile();
  const router = useRouter();
  const findActiveIndex = useCallback(() => {
    if (!!scrollableContainerRef) {
      let newActive;
      for (let i = 0; i < Navigation.length; i++) {
        const item = Navigation[i];
        const node = document.getElementById((item.href as string).substring(2));
        if (
          node &&
          node.offsetTop >= scrollableContainerRef.current!.scrollTop &&
          node.offsetTop < scrollableContainerRef.current!.scrollTop + scrollableContainerRef.current!.clientHeight
        ) {
          newActive = item;
          break;
        }
      }
      if (newActive && active !== newActive.href) {
        setActive(newActive.href as string);
      }
    }
  }, [active, scrollableContainerRef]);

  useEffect(() => {
    setActive(router.pathname === '/' ? '/#home' : router.pathname);
  }, [router.pathname]);
  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(findActiveIndex, 166, scrollableContainerRef?.current!, !!scrollableContainerRef);
  return (
    <Layout>
      {defaultProduct === 'aid' ? <AidBayanatLogo viewBox="0 0 258 48" /> : <GiqBayanatLogo viewBox="0 0 262 48" />}
      {!isMobile ? (
        <>
          {Navigation.map((nav) => (
            <StyledLink key={nav.label} href={nav.href} selected={nav.href === active}>
              {t(nav.label)}
            </StyledLink>
          ))}
          <StyledLink href={status === 'authenticated' ? '/map' : '/login?callbackUrl=%2Fmap'} sx={{ color: 'action.active' }}>
            {t(`home:${status === 'authenticated' ? 'continue' : 'login'}`, { product: t(`common:${defaultProduct}_product`) })}
          </StyledLink>
        </>
      ) : (
        <MobileMenu />
      )}
      {showUaesa && <UaeSALogo height="48" viewBox="0 0 255 52" />}
      <MobileNotice open={isMobile} />
    </Layout>
  );
};

export default Header;
