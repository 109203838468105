import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import GiqBayanatLogo from '@/assets/svg/giq_bayanat.svg';
import AidBayanatLogo from '@/assets/svg/aid_bayanat.svg';
import UaeSALogo from '@/assets/svg/uaesa_logo.svg';
import { getYear } from 'date-fns';
import Link, { LinkProps } from '@/components/Link';
import { getConfig } from '@/config';
import { MouseEventHandler } from 'react';
import { useRouter } from 'next/router';
import { stopEvent } from '@/utils/dom';
import { useSession } from 'next-auth/react';
import { Localisations } from '@/models';
import { useCreateUpdateUserSettings, useUserSettings } from '@/hooks/useSettings';

export const BottomShadow = styled('div')`
  position: absolute;
  height: 120px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => `linear-gradient(in oklab 180deg, transparent 0%, ${theme.vars.palette.background.default} 100%)`};
  ${({ theme }) => theme.getColorSchemeSelector('aid')} {
    background: linear-gradient(in oklab 180deg, rgba(18, 23, 35, 0) 0%, #121723 100%);
  }
  z-index: -1;
`;

const StyledLink = styled((props: LinkProps) => <Link {...props} variant="button" color="text.primary" />)`
  text-decoration: none;
  transition: ${({ theme }) =>
    theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.emphasized,
      duration: theme.transitions.duration.short,
    })};
  &:hover {
    transform: scale(1.1);
  }
`;

const { showUaesa, defaultProduct } = getConfig();

const localisationStateMachine: Record<string, Localisations> = {
  [Localisations.ar]: Localisations.en,
  [Localisations.en]: Localisations.ar,
};

const Footer = () => {
  const { t, i18n } = useTranslation(['common', 'home']);
  const router = useRouter();
  const { data: session } = useSession();
  const { data: userSettings } = useUserSettings(!!session?.user.id);
  const updateUserSettings = useCreateUpdateUserSettings();

  const onLanguageChange: MouseEventHandler = (e) => {
    stopEvent(e);
    const currentLanguageSetting = userSettings?.language || router.locale || Localisations.en;
    const nextLocalisation = localisationStateMachine[currentLanguageSetting] || Localisations.en;

    const id = session?.user.id;

    if (!id || !userSettings) {
      router.push(router.asPath, router.asPath, { locale: nextLocalisation });

      return;
    }

    const settings = {
      ...userSettings,
      language: nextLocalisation,
    };

    updateUserSettings.mutate(
      { settings },
      {
        onSuccess: () => {
          if (settings.language && settings.language !== userSettings?.language) {
            router.push(router.asPath, router.asPath, { locale: settings.language });
          }
        },
      },
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'space-between', paddingBlockEnd: 6 }}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" alignItems="center" gap={9}>
            {defaultProduct === 'aid' ? (
              <AidBayanatLogo width="180" height="35" viewBox="0 0 258 48" />
            ) : (
              <GiqBayanatLogo width="180" height="35" viewBox="0 0 262 48" />
            )}
            {showUaesa && <UaeSALogo width="171" height="35" viewBox="0 0 255 52" />}
          </Box>
          <Typography variant="body2" color="text.secondary">
            {t('home:copyright', { year: getYear(new Date()) })}
            {showUaesa && t('home:copyright_uaesa')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={10}>
          <StyledLink href="https://www.bayanat.ai">Bayanat.Ai</StyledLink>
          {showUaesa && <StyledLink href="https://space.gov.ae">{t('home:uaesa')}</StyledLink>}
          <StyledLink href="/">{t('home:privacy_policy')}</StyledLink>
          <StyledLink href="/">{t('home:terms_conditions')}</StyledLink>
          {i18n.language === Localisations.en && (
            <StyledLink href="/" onClick={onLanguageChange}>
              {t('common:language_name_ar')}
            </StyledLink>
          )}
          {i18n.language === Localisations.ar && (
            <StyledLink href="/" onClick={onLanguageChange}>
              {t('common:language_name_en')}
            </StyledLink>
          )}
        </Box>
      </Box>
      <BottomShadow />
    </>
  );
};

export default Footer;
